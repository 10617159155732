import {useEffect, useState, useCallback} from 'react';
import {QaImg, DataUri} from '../image';
import {
    generatePdf,
    ResultType,
    PdfContentImage,
} from './generate';
export * from './generate';

export const inchToPt = (inches: number): number => inches * 72;

export const usePdf = (
    shouldGenerate: boolean,
    image: QaImg | null,
    widthInches: number,
    heightInches: number
): string | null => {
    const [pdf, setPdf] = useState<DataUri | null>(null);
    const dataUri = image ? image.dataUri : null;

    const width = inchToPt(widthInches);
    const height = inchToPt(heightInches);

    const loadPdf = useCallback(async (dataUri: DataUri): Promise<string | null> => {
        return await generatePdf<string>({
            size: [ width, height ],
            resultType: ResultType.url,
            content: [
                new PdfContentImage()
                    .s('data', dataUri)
                    .s('width', width)
                    .s('height', height)
            ],
        });
    }, [width, height]);

    useEffect(() => {
        setPdf(null);

        if (!dataUri || !shouldGenerate)
            return;
        loadPdf(dataUri).then(setPdf);
    }, [dataUri, loadPdf, shouldGenerate]);

    return pdf;
};
