import React, {FC} from "react";

import styled from '@emotion/styled';
import {media} from "../media";
import { QaImg } from "../../image";


export type ImageViewerProps = {
    image: QaImg | null
};

const BG_COLOR_1 = 'rgba(0, 0, 0, 0.5)';
const BG_SQUARE_SIZE = 30;

export const Wrapper = styled.div`
    z-index: 30;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    background-image: linear-gradient(45deg, ${BG_COLOR_1} 25%, transparent 25%), linear-gradient(-45deg, ${BG_COLOR_1} 25%, transparent 25%), linear-gradient(45deg, transparent 75%, ${BG_COLOR_1} 75%), linear-gradient(-45deg, transparent 75%, ${BG_COLOR_1} 75%);
    background-size: ${BG_SQUARE_SIZE}px ${BG_SQUARE_SIZE}px;
    background-position: 0 0, 0 ${BG_SQUARE_SIZE/2}px, ${BG_SQUARE_SIZE/2}px -${BG_SQUARE_SIZE/2}px, -${BG_SQUARE_SIZE/2}px 0px;
`;
export const Image = styled.img`
    position: absolute;
    object-fit: contain;
    top: 2%;
    left: 2%;
    width: 96%;
    height: 96%;
    ${media.medium(`
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
    `)}
    ${media.large(`
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
    `)}
`;

export const ImageViewer: FC<ImageViewerProps> = ({image, children}) => {
    const {
        dataUri = undefined,
        // id = undefined,
    } = image || {};

    return (
        <Wrapper>
            {dataUri
                ? <Image src={dataUri} alt="Your QaImg" />
                : <p>Loading...</p>
            }
            {children}
        </Wrapper>
    )
};