import React, {
    FC,
    useState,
    InputHTMLAttributes,
    ReactElement,
    ReactText,
    useRef,
    useEffect,
} from 'react';
import styled from '@emotion/styled';


export enum InputLooks {
    dark = 'inputTheme/dark',
    light = 'inputTheme/light',
};

interface StyleInputProps {
    look?: InputLooks;
    focused?: boolean;
};
const StyledInput = styled.input<StyleInputProps>`
    background-color: ${props => props.focused ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
    color: ${props => props.look === InputLooks.dark
        ? (props.focused ? 'white' : '#999')
        : (props.focused ? 'black' : '#333')
    };
    border: none;
    border-radius: .25rem;
    outline: none;
    width: 60px;
    text-align: left;
    box-sizing: border-box;
    padding: .5rem;
`;
StyledInput.defaultProps = {type: 'text'};

const Label = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    min-width: 100px;
    box-sizing: border-box;
    margin-left: 1rem;
`;
const LabelText = styled.div`
    color: #FFFFFF99;
    font-size: .8rem;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
`;

export type InputProps = {
    value: string;
    setValue: (value: string) => string;
    label: ReactElement | ReactText;
    look?: InputLooks;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input: FC<InputProps> = ({
    setValue,
    label,
    value,
    look = InputLooks.dark,
    ...props
}) => {
    const [focused, setFocused] = useState<boolean>(false);
    const [internalValue, setInternalValue] = useState<string>(value);
    const [valueUponFocus, setValueUponFocus] = useState<string>(value);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // allow external update
        if (value !== internalValue) {
            setInternalValue(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Label>
            <LabelText>{label}</LabelText>
            <StyledInput
                ref={inputRef}
                onFocus={() => {
                    setValueUponFocus(internalValue);
                    setFocused(true);
                }}
                onBlur={() => {
                    setInternalValue(
                        setValue(
                            internalValue || valueUponFocus
                        )
                    );
                    setFocused(false);
                }}
                focused={focused}
                look={look}
                onChange={() => {
                    setInternalValue(
                        inputRef.current
                            ? inputRef.current.value
                            : ''
                    );
                }}
                value={internalValue}
                {...props}
            />
        </Label>
    )
};
