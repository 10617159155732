type Breakpoint = 'medium' | 'large' | 'xlarge';

export const breakpoints: Record<Breakpoint, number> = {
    medium: 768,
    large: 992,
    xlarge: 1200,
};

type MediaControls = Record<Breakpoint, (styles: string) => string>;

export const media: MediaControls = Object.keys(breakpoints).reduce(
    (acc: MediaControls, description) => {
        acc[description as Breakpoint] = (styles: string) => {
            return `
                @media (min-width: ${breakpoints[description as Breakpoint]}px) {
                    ${styles}
                }
            `;
         };
        return acc;
    },
    {} as MediaControls
) as MediaControls;

