import React, {FC, useCallback, useState, useEffect} from 'react';
import styled from '@emotion/styled';
import {Global, css} from '@emotion/core';
import {saveAs} from 'file-saver';

import {useGeneratedImage} from './image';
import {usePdf} from './pdf';
import {
    Controls,
    useControlsState,
    useControlsEffects,
    ImageViewer,
    ZipModal,
    media,
} from './ui';


const GlobalStyles: FC = () => (
    <Global styles={css`
        html, body {
            margin: 0;
            padding: 0;
            background: #101010;
            color: #eee;
            font-family: 'Courier Prime', monospace;
        }
        * {
            font-family: 'Courier Prime', monospace;
        }
        h1, h2, h3 {
            font-family: 'Alfa Slab One', sans-serif;
        }
    `} />
);
const Wrapper = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
`;
const Header = styled.h1`
    font-family: 'Alfa Slab One', sans-serif;
    color: #DDD;
    /* -webkit-text-stroke: 1px #0085d1; */
    z-index: -1;
    ${() => {
        const shadows = [
            '1px 1px 0 #0085d1, -1px 1px 0 #0085d1, -1px -1px #0085d1, 1px -1px #0085d1'
        ];
        for (let i = 0; i < 1000; i++) {
            shadows.push(`${i}px ${i}px 0 #00000006`);
        }
        return `text-shadow: ${shadows.join(', ')};`;
    }}
    font-size: 3rem;
    ${media.medium(`
        font-size: 2rem;
    `)}
`;

export const App: FC = () => {
    const controlsStateApi = useControlsState();
    const [zipMode, setZipMode] = useState<boolean>(false);

    const {
        imageWidth,
        imageHeight,
        pdfWidth,
        pdfHeight,
        zipQuantity,
        zipType,
        setZipType,
    } = controlsStateApi;

    const {
        image,
        generate,
    } = useGeneratedImage(imageWidth, imageHeight);

    const pdf = usePdf(true, image, pdfWidth, pdfHeight);
    useControlsEffects(controlsStateApi, !image);

    const {
        dataUri = undefined,
        id = undefined,
    } = image || {};

    const downloadImage = useCallback(() => {
        if (!dataUri || !id)
            throw new Error('Image must be loaded before downloading');

        saveAs(dataUri, `qaimg_${id}.png`);
    }, [dataUri, id]);
    const downloadPdf = useCallback(() => {
        if (!id || !pdf)
            throw new Error('Image and PDF must be loaded before downloading');

        saveAs(pdf, `qaimg_${id}.pdf`)
    }, [id, pdf]);
    const openPdf = useCallback(() => {
        if (!pdf)
            throw new Error('PDF must be loaded before it can be opened');

        window.open(pdf)
    }, [pdf]);
    const generateImage = useCallback(() => {
        if (pdf)
            window.URL.revokeObjectURL(pdf);
        generate();
    }, [generate, pdf]);

    useEffect(() => {
        generateImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageWidth, imageHeight]);
    useEffect(() => {
        setZipType(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipMode]);

    return (
        <Wrapper>
            <GlobalStyles />
            <Controls
                imageLoaded={!!image}
                pdfLoaded={!!pdf}
                onDownloadImg={downloadImage}
                onDownloadPdf={downloadPdf}
                onOpenPdf={openPdf}
                onGenerateImage={generateImage}
                showZip={() => setZipMode(true)}
                hideZip={() => setZipMode(false)}
                zipMode={zipMode}
                {...controlsStateApi}
            >
                <Header>QaImg</Header>
            </Controls>
            <ImageViewer image={image} />
            {zipMode && zipType && (
                <ZipModal
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    pdfWidth={pdfWidth}
                    pdfHeight={pdfHeight}
                    quantity={zipQuantity}
                    type={zipType}
                    setType={setZipType}
                />
            )}
        </Wrapper>
    );
};
