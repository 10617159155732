import {MaterialIcons} from '../svg';
import {loadImage, ImageSize} from './load';


const iconKeys = Object.keys(MaterialIcons);

export const randomIcon = async (size?: ImageSize): Promise<HTMLImageElement> => {
    const iconKey = iconKeys[Math.floor(Math.random() * iconKeys.length)];

    try {
        return await loadImage(MaterialIcons[iconKey], size);
    } catch(error) {
        throw new Error(`Unable to load SVG :: ${error}`);
    }
};
