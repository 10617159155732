import React, {FC, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {saveAs} from 'file-saver';
import {ZipType} from '.';
import {useZip} from './useZip';
import {Pixels} from '../controls';
import { getDisplayDate } from '../../datetime';

const Wrap = styled.div<{ backdrop: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.backdrop
        ? `
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(5px) grayscale(100%);
        `
        : `
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0) grayscale(0);
        `
    }
    transition: background 1s, backdrop-filter 1s;
    z-index: 999999999;
`;

const Window = styled.div`
    padding: 1em;
    background-color: #eee;
    color: black;
    border-radius: .25em;
    min-height: 20vh;
    min-width: 33vw;
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export interface ZipModalProps {
    imageWidth: Pixels;
    imageHeight: Pixels;
    pdfWidth: number;
    pdfHeight: number;
    quantity: number;
    type: ZipType;
    setType: (zipType?: ZipType) => void;
};


export const ZipModal: FC<ZipModalProps> = ({
    imageWidth,
    imageHeight,
    pdfWidth,
    pdfHeight,
    quantity,
    type,
    setType,
}) => {
    const [backdrop, setBackdrop] = useState<boolean>(false);
    const [hasDownloaded, setHasDownloaded] = useState<boolean>(false);

    useEffect(() => {
        setBackdrop(true);
    }, []);

    const zipApi = useZip(
        type,
        quantity,
        imageWidth,
        imageHeight,
        pdfWidth,
        pdfHeight
    );

    const displayType = type === ZipType.image ? 'images' : 'pdfs';

    return (
        <Wrap backdrop={backdrop}>
            <Window>
                {!zipApi.zip ? <>
                    <p>
                        Please wait, generating {zipApi.generatedQuantity} of {quantity} {displayType}...
                    </p>
                    <progress max={quantity} value={zipApi.generatedQuantity} />
                    <button type="button" onClick={() => setType(undefined)}>
                        Cancel
                    </button>
                </> : <>
                    <button type="button" onClick={() => {
                        setHasDownloaded(true);
                        saveAs(
                            zipApi.zip as Blob,
                            `qaimg_${quantity}_${displayType}_${getDisplayDate()}.zip`
                        );
                    }}>
                        Download {quantity} {displayType}
                    </button>
                    <button type="button" onClick={() => {
                        if (!hasDownloaded) {
                            if (!window.confirm('Please be sure to download the package, you won\'t be able to get back to this. Are you sure you wish to dismiss the results?')) {
                                return;
                            }
                        }
                        setType(undefined);
                    }}>
                        Dismiss
                    </button>
                </>}
            </Window>
        </Wrap>
    )
};
