
export const padZero = (str: string | number, len: number = 2): string => {
  const zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
};

export const loopRectangle = (
  startX: number,
  startY: number,
  width: number,
  height: number,
  fn: (x: number, y: number) => void
) => {
  const total = width * height;
  for (let i = 0; i < total; i++) {
      const y = startY + Math.floor(i / width);
      const x = startX + (i % width);
      fn(x, y);
  }
};
