import {useEffect, useState, useRef, useCallback} from 'react';
import JSZip from 'jszip';
import {ZipType} from '.';
import {Pixels} from '../controls';
import { generateImage } from '../../image';
import { generatePdf, inchToPt, ResultType, PdfContentImage } from '../../pdf';

export type ZipApi = {
    generatedQuantity: number;
    totalQuantity: number;
    zip: Blob | undefined;
};

export const useZip = (
    type: ZipType,
    quantity: number,
    imageWidth: Pixels,
    imageHeight: Pixels,
    pdfWidth: number,
    pdfHeight: number,
): ZipApi => {
    // do this with a ref rather than state so we can change it without
    // causing re-render and so we don't have to worry about accessing
    // values in our closures. refs are refs.. they're instantaneously
    // up-to-date references.
    const cancelledRef = useRef<boolean>(false);
    const [generatedQuantity, setGeneratedQuantity] = useState<number>(0);
    const [finishedZip, setFinishedZip] = useState<Blob | undefined>();
    const zipRef = useRef<JSZip | undefined>();

    const generateOne = useCallback(async () => {
        if (!zipRef.current) {
            throw new Error('Something went wrong, cant add file');
        }

        const image = await generateImage(imageWidth, imageHeight);
        if (cancelledRef.current) {
            console.log('cancelled :: after generating image');
            return;
        }

        if (type === ZipType.image) {
            const blob = await (await fetch(image.dataUri)).blob(); 
            if (cancelledRef.current) {
                console.log('cancelled :: after fetching dataUri into blob');
                return;
            }

            zipRef.current.file(`qaimg_${image.id}.png`, blob);
        } else if (type === ZipType.pdf) {

            const widthConverted = inchToPt(pdfWidth);
            const heightConverted = inchToPt(pdfHeight);

            const pdf = await generatePdf<Blob>({
                size: [widthConverted, heightConverted],
                resultType: ResultType.blob,
                content: [
                    new PdfContentImage()
                        .s('data', image.dataUri)
                        .s('width', widthConverted)
                        .s('height', heightConverted)
                ]
            });
            if (cancelledRef.current) {
                console.log('cancelled :: after generating PDF');
                return;
            }
            zipRef.current.file(`qaimg_${image.id}.pdf`, pdf);
        }
    }, [
        type,
        pdfWidth,
        pdfHeight,
        imageWidth,
        imageHeight,
    ]);

    // start the process
    useEffect(() => {
        cancelledRef.current = false;
        setFinishedZip(undefined);
        setGeneratedQuantity(0);
        zipRef.current = new JSZip();
    }, [type, quantity, generateOne]);

    // take a step each time a new file is added to the zip.
    // this allows us to use dynamically updating state throughout
    // this process (such as "cancelled")
    useEffect(() => {
        (async () => {
            if (cancelledRef.current) {
                console.log('cancelled :: at top of render effect');
                return;
            }
            if (generatedQuantity < quantity) {
                await generateOne();
                if (cancelledRef.current) {
                    console.log('cancelled :: after generating one (also possibly cancelled), before setting quantity');
                    return;
                }
                setGeneratedQuantity(
                    generatedQuantity + 1
                );
            } else {
                if (!zipRef.current) {
                    throw new Error('Unable to finalize zip');
                }
                const content = await zipRef.current.generateAsync({type:"blob"});
                if (cancelledRef.current) {
                    console.log('cancelled :: after generating zip file');
                    return;
                }
                setFinishedZip(content);
            }
        })();
    }, [generatedQuantity, generateOne, quantity]);

    // on unload
    useEffect(() => () => {
        cancelledRef.current = true;
    }, []);

    return {
        generatedQuantity,
        totalQuantity: quantity,
        zip: finishedZip,
    };
};
