export * from './Input';


export const numericSetter = (
    setter: (input: number) => void,
    max?: number,
) => (value: string) => {
    const numeric = parseFloat(value.replace(/[^0-9.]/g, ''));

    if (!isNaN(numeric)) {
        const newVal = max && numeric > max ? max : numeric;
        setter(newVal);
        return newVal.toString();
    }
    return value;
};
