import {padZero} from '../number';

export const getDisplayDate = (): string => {
    const date = new Date();
    const year = date.getFullYear();
    const month = padZero(date.getMonth(), 2);
    const day = padZero(date.getDate(), 2);
    
    return `${year}-${month}-${day}`;
};

export const getDisplayTime = () => {
    const date = new Date();
    const numberHour = date.getHours();
    const hour = padZero(numberHour - (numberHour > 12 ? 12 : 0), 2);
    const min = padZero(date.getMinutes(), 2);
    const secs = padZero(date.getSeconds(), 2);
    
    return `${hour}:${min}:${secs}`;
};
