import accessibility from './material-icons/accessibility_black_24dp.svg';
import agriculture from './material-icons/agriculture_black_24dp.svg';
import air from './material-icons/air_black_24dp.svg';
import anchor from './material-icons/anchor_black_24dp.svg';
import attachment from './material-icons/attachment_black_24dp.svg';
import audiotrack from './material-icons/audiotrack_black_24dp.svg';
import bathtub from './material-icons/bathtub_black_24dp.svg';
import blender from './material-icons/blender_black_24dp.svg';
import bolt from './material-icons/bolt_black_24dp.svg';
import bug_report from './material-icons/bug_report_black_24dp.svg';
import build from './material-icons/build_black_24dp.svg';
import business_center from './material-icons/business_center_black_24dp.svg';
import cake from './material-icons/cake_black_24dp.svg';
import camera_alt from './material-icons/camera_alt_black_24dp.svg';
import campaign from './material-icons/campaign_black_24dp.svg';
import casino from './material-icons/casino_black_24dp.svg';
import child_care from './material-icons/child_care_black_24dp.svg';
import child_friendly from './material-icons/child_friendly_black_24dp.svg';
import cloud from './material-icons/cloud_black_24dp.svg';
import coffee_maker from './material-icons/coffee_maker_black_24dp.svg';
import color_lens from './material-icons/color_lens_black_24dp.svg';
import delivery_dining from './material-icons/delivery_dining_black_24dp.svg';
import directions_bike from './material-icons/directions_bike_black_24dp.svg';
import directions_boat from './material-icons/directions_boat_black_24dp.svg';
import directions_car from './material-icons/directions_car_black_24dp.svg';
import done from './material-icons/done_black_24dp.svg';
import door_back from './material-icons/door_back_black_24dp.svg';
import electrical_services from './material-icons/electrical_services_black_24dp.svg';
import emoji_emotions from './material-icons/emoji_emotions_black_24dp.svg';
import favorite from './material-icons/favorite_black_24dp.svg';
import fitness_center from './material-icons/fitness_center_black_24dp.svg';
import flight from './material-icons/flight_black_24dp.svg';
import gavel from './material-icons/gavel_black_24dp.svg';
import grade from './material-icons/grade_black_24dp.svg';
import handyman from './material-icons/handyman_black_24dp.svg';
import hourglass_full from './material-icons/hourglass_full_black_24dp.svg';
import house from './material-icons/house_black_24dp.svg';
import ice_skating from './material-icons/ice_skating_black_24dp.svg';
import landscape from './material-icons/landscape_black_24dp.svg';
import local_cafe from './material-icons/local_cafe_black_24dp.svg';
import local_laundry_service from './material-icons/local_laundry_service_black_24dp.svg';
import lunch_dining from './material-icons/lunch_dining_black_24dp.svg';
import mail from './material-icons/mail_black_24dp.svg';
import maps_home_work from './material-icons/maps_home_work_black_24dp.svg';
import menu_book from './material-icons/menu_book_black_24dp.svg';
import nightlight_round from './material-icons/nightlight_round_black_24dp.svg';
import pan_tool from './material-icons/pan_tool_black_24dp.svg';
import park from './material-icons/park_black_24dp.svg';
import pets from './material-icons/pets_black_24dp.svg';
import phone from './material-icons/phone_black_24dp.svg';
import plumbing from './material-icons/plumbing_black_24dp.svg';
import pub from './material-icons/public_black_24dp.svg';
import radio from './material-icons/radio_black_24dp.svg';
import restaurant from './material-icons/restaurant_black_24dp.svg';
import sailing from './material-icons/sailing_black_24dp.svg';
import save from './material-icons/save_black_24dp.svg';
import savings from './material-icons/savings_black_24dp.svg';
import school from './material-icons/school_black_24dp.svg';
import sports_score from './material-icons/sports_score_black_24dp.svg';
import theater_comedy from './material-icons/theater_comedy_black_24dp.svg';
import theaters from './material-icons/theaters_black_24dp.svg';
import thumb_up from './material-icons/thumb_up_black_24dp.svg';
import tour from './material-icons/tour_black_24dp.svg';
import two_wheeler from './material-icons/two_wheeler_black_24dp.svg';
import videogame_asset from './material-icons/videogame_asset_black_24dp.svg';


export const MaterialIcons: Record<string, string> = {
    accessibility,
    agriculture,
    air,
    anchor,
    attachment,
    audiotrack,
    bathtub,
    blender,
    bolt,
    bug_report,
    build,
    business_center,
    cake,
    camera_alt,
    campaign,
    casino,
    child_care,
    child_friendly,
    cloud,
    coffee_maker,
    color_lens,
    delivery_dining,
    directions_bike,
    directions_boat,
    directions_car,
    done,
    door_back,
    electrical_services,
    emoji_emotions,
    favorite,
    fitness_center,
    flight,
    gavel,
    grade,
    handyman,
    hourglass_full,
    house,
    ice_skating,
    landscape,
    local_cafe,
    local_laundry_service,
    lunch_dining,
    mail,
    maps_home_work,
    menu_book,
    nightlight_round,
    pan_tool,
    park,
    pets,
    phone,
    plumbing,
    pub,
    radio,
    restaurant,
    sailing,
    save,
    savings,
    school,
    sports_score,
    theater_comedy,
    theaters,
    thumb_up,
    tour,
    two_wheeler,
    videogame_asset,
};
