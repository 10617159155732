import styled from '@emotion/styled';

export enum FlexDirection {
    column = 'flex:column',
    row = 'flex:row',
};

interface FlexProps {
    direction?: FlexDirection;
    styles?: string;
    flex?: number;
};

export const Flex = styled.div<FlexProps>`
    display: flex;
    flex: ${props => props.flex || '1'};
    flex-direction: ${props => (
        props.direction === FlexDirection.row
            ? 'row'
            : 'column'
    )};
    position: relative;
`;
