import {useState, useEffect} from 'react';
import {ZipType} from '../zip';

export type Pixels = number;

export enum PdfUom {
    inch = 'pdfUom/inch',
    pt = 'pdfUom/pt',
};

export type ControlsStateApi = {
    shouldMakePdf: boolean;
    setShouldMakePdf: (shouldMakePdf: boolean) => void;
    imageWidth: Pixels;
    setImageWidth: (imageWidth: Pixels) => void;
    imageHeight: Pixels;
    setImageHeight: (imageHeight: Pixels) => void;
    pdfWidth: number;
    setPdfWidth: (pdfWidth: number) => void;
    pdfHeight: number;
    setPdfHeight: (pdfHeight: number) => void;
    pdfUoM: PdfUom;
    setPdfUoM: (pdfUom: PdfUom) => void;
    zipQuantity: number;
    setZipQuantity: (zipQuantity: number) => void;
    zipType?: ZipType;
    setZipType: (zipType?: ZipType) => void;
};

export const useControlsState = (): ControlsStateApi => {
    const [
        shouldMakePdf,
        setShouldMakePdf
    ] = useState<ControlsStateApi['shouldMakePdf']>(false);
    const [
        imageWidth,
        setImageWidth,
    ] = useState<ControlsStateApi['imageWidth']>(800);
    const [
        imageHeight,
        setImageHeight,
    ] = useState<ControlsStateApi['imageHeight']>(1050);
    const [
        pdfWidth,
        setPdfWidth,
    ] = useState<ControlsStateApi['pdfWidth']>(8);
    const [
        pdfHeight,
        setPdfHeight,
    ] = useState<ControlsStateApi['pdfHeight']>(10.5);
    const [
        pdfUoM,
        setPdfUoM,
    ] = useState<ControlsStateApi['pdfUoM']>(PdfUom.inch);
    const [
        zipQuantity,
        setZipQuantity,
    ] = useState<ControlsStateApi['zipQuantity']>(10);
    const [
        zipType,
        setZipType,
    ] = useState<ControlsStateApi['zipType']>();


    return {
        shouldMakePdf,
        setShouldMakePdf,
        imageWidth,
        setImageWidth,
        imageHeight,
        setImageHeight,
        pdfWidth,
        setPdfWidth,
        pdfHeight,
        setPdfHeight,
        pdfUoM,
        setPdfUoM,
        zipQuantity,
        setZipQuantity,
        zipType,
        setZipType,
    };
};

export const useControlsEffects = (
    api: ControlsStateApi,
    imageLoaded: boolean
): void => {
    useEffect(() => {
        api.setShouldMakePdf(false);
    }, [api, imageLoaded]);
};
