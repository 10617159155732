import {useState, useEffect, useCallback} from 'react';
import {generateImage} from './generate';
export * from './generate';
export * from './load';


export type QaImg = {
    dataUri: string;
    id: string;
    width: number;
    height: number;
    backgroundColor: string;
    foregroundColor: string;
};

export const useGeneratedImage = (width: number, height: number) => {
    const [image, setImage] = useState<QaImg | null>(null);

    const generate = useCallback(async () => {
        setImage(null);
        setImage(
            await generateImage(width, height)
        );
    }, [width, height]);

    useEffect(() => {
        generate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        image,
        generate,
    };
};

