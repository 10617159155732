import { padZero } from "../../number";

export class Hex6Color {
    public hex: string;
    private r: number;
    private g: number;
    private b: number;

    constructor(hex: string) {
        if (hex.indexOf("#") === 0) {
            hex = hex.slice(1);
        }
        this.hex = hex;
        this.r = parseInt(hex.substring(0, 2), 16);
        this.g = parseInt(hex.substring(2, 4), 16);
        this.b = parseInt(hex.substring(4, 6), 16);
    };

    invert = (): Hex6Color => {
        return new Hex6Color(
            Hex6Color.toHexString(255 - this.r, 255 - this.g, 255 - this.b)
        );
    };

    rgba = (alpha: number) => `rgba(${this.r}, ${this.g}, ${this.b}, ${alpha})`;

    get R(): number { return this.r; };
    get G(): number { return this.g; };
    get B(): number { return this.b; };

    get luminance(): number {
        // https://stackoverflow.com/a/596243

        // standard for certain colour spaces
        const formula1 = (0.2126*this.r + 0.7152*this.g + 0.0722*this.b);

        // perceived option 1
        // const formula2 = (0.299*this.r + 0.587*this.g + 0.114*this.b);

        // perceived option 2, slower
        // const formula3 = Math.sqrt(
        //     0.299*Math.pow(this.r, 2)
        //     + 0.587*Math.pow(this.g, 2)
        //     + 0.114*Math.pow(this.b, 2)
        // );
        return formula1;
    };

    contrast = (color: Hex6Color): number => Hex6Color.contrast(this, color);

    toString(): string {
        return Hex6Color.toHexString(this.r, this.g, this.b);
    };

    static toHexString(r: number, g: number, b: number): string {
        return (
            "#" +
            padZero(r.toString(16)) +
            padZero(g.toString(16)) +
            padZero(b.toString(16))
        );
    };

    static random(minContrast?: number): Hex6Color {
        const getRandom = () => {
            const letters: string = "0123456789ABCDEF";
            const colors: string[] = [];
            for (var i = 0; i < 6; i++) {
                colors.push(letters[Math.floor(Math.random() * 16)]);
            }

            return new Hex6Color(colors.join(""));
        };

        let color = getRandom();

        if (minContrast) {
            let iterations: number = 0;
            while (color.contrast(color.invert()) < minContrast) {
                iterations++;
                color = getRandom();
            }
            console.log(`It took ${iterations} to get this color`);
        }

        return color;
    };

    static contrast(colorA: Hex6Color, colorB: Hex6Color): number {
        return (colorA.luminance + 0.05) / (colorB.luminance + 0.05);
    }

    static __debugConstrast__(): void {
        const LocalHex = Hex6Color;
        console.log(
            Array(1000).fill(undefined).map(() => {
                let color = LocalHex.random();
                return color.contrast(color.invert())
            }).reduce((acc, contrastValue) => {
                const index = Math.floor(contrastValue);
    
                if (!acc[`${index}`])
                    acc[`${index}`] = [];
    
                acc[`${index}`].push(contrastValue);
                return acc;
            }, {} as Record<string, number[]>)
        );
    
        debugger;
    };
}
