import React, {FC} from 'react';
import styled from '@emotion/styled';

import {ControlsStateApi} from './useControlsState';
import {Input, numericSetter} from '../form';
import {media} from "../media";
import {Flex, FlexDirection} from "../flex";
import {ZipType} from '../zip';

const Wrapper = styled.div`
    z-index: 40;
    display: flex;
    flex-direction: row;
    background-color: #222;
    border-bottom: 1px solid #555;
    position: relative;
    justify-content: center;
    overflow: hidden;
`;

const MaxWidth = styled.div`
    max-width: 100%;
    ${media.medium(`
        width: 90vw;
    `)}
    ${media.large(`
        width: 70vw;
    `)}
    ${media.xlarge(`
        width: 50vw;
    `)}
`;

const Spacer = styled.div`
    flex: 10;
`;

const Shadow = styled.div`
    position: absolute;
    opacity: .5;
    border-top: 1px solid black;
    left: 0;
    right: 0;
    bottom: -27px;
    height: 25px;
    background: rgb(0, 0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
`;

const ActionDeck = styled.div`
    display: flex;
    flex-direction: column;
    ${media.medium(`flex-direction: row;`)}
    justify-content: flex-end;
    ${media.large(`justify-content: center;`)}
`;

const ActionLabel = styled.span`
    padding: .5rem;
    color: #FFFFFF99;
`;

const Action = styled.button<{ 'data-radleft'?: boolean; 'data-radright'?: boolean; }>`
    border: none;
    background-color: #0085d1;
    color: white;
    text-shadow: 0 0 5px #000000FF;
    font-size: 1rem;
    margin: .25rem 0;
    padding: .3rem .7rem;
    :hover {
        background-color: #56BED5;
    }
    :disabled {
        background-color: #777777;
        color: #222;
        text-shadow: 0 0 5px #FFFFFF44;
    }
    ${p => media.medium(`
        border-radius: ${p['data-radleft'] ? '1rem' : '0'} ${p['data-radright'] ? '1rem' : '0'} ${p['data-radright'] ? '1rem' : '0'} ${p['data-radleft'] ? '1rem' : '0'};
        ${p['data-radleft'] ? '' : 'border-left: 1px solid #FFFFFF44;'}
        ${p['data-radright'] ? '' : 'border-right: 1px solid #00000066;'}
    `)}
`;
const HeaderGroup = styled.div`
    display: flex;
    flex-direction: column;
    ${media.medium(`flex-direction: row;`)}
`;
const DimGroup = styled(Flex)`
    background-color: #FFFFFF11;
    margin-bottom: 2px;
    padding: 0 1rem;
    border-radius: .5rem;
    > span {
        color: #FFFFFF88;
        line-height: 2.6rem;
        font-size: 1.25rem;
        opacity: 0.6;
        text-shadow: 0 0 3px black, 0 1px 3px black, 0 1px 3px black;
    }
`;

export type ControlsProps = {
    imageLoaded: boolean; 
    pdfLoaded: boolean;
    onDownloadImg: () => void;
    onOpenPdf: () => void;
    onDownloadPdf: () => void;
    onGenerateImage: () => void;
    showZip: () => void;
    hideZip: () => void;
    zipMode: boolean;
} & ControlsStateApi;

export const Controls: FC<ControlsProps> = ({
    children,
    imageLoaded,
    pdfLoaded,
    onDownloadImg,
    onOpenPdf,
    onDownloadPdf,
    onGenerateImage,
    showZip,
    hideZip,
    zipMode,

    imageWidth,
    setImageWidth,
    imageHeight,
    setImageHeight,
    pdfWidth,
    setPdfWidth,
    pdfHeight,
    setPdfHeight,
    zipQuantity,
    setZipQuantity,
    setZipType,
}) => {
    return (
        <>
            <Wrapper>
                <MaxWidth>
                    <HeaderGroup>
                        {children}
                        <Spacer />
                        <Flex>
                            <DimGroup direction={FlexDirection.row}>
                                <span>Img</span>
                                <Input
                                    label="Wid"
                                    value={imageWidth ? imageWidth.toString() : ''}
                                    setValue={numericSetter(setImageWidth)}
                                />
                                <Input
                                    label="Hei"
                                    value={imageHeight ? imageHeight.toString() : ''}
                                    setValue={numericSetter(setImageHeight)}
                                />
                            </DimGroup>
                            <DimGroup direction={FlexDirection.row}>
                                <span>Pdf</span>
                                <Input
                                    label="Wid"
                                    value={pdfWidth ? pdfWidth.toString() : ''}
                                    setValue={numericSetter(setPdfWidth)}
                                />
                                <Input
                                    label="Hei"
                                    value={pdfHeight ? pdfHeight.toString() : ''}
                                    setValue={numericSetter(setPdfHeight)}
                                />
                            </DimGroup>
                        </Flex>
                    </HeaderGroup>
                    <ActionDeck>
                        <ActionLabel>
                            Mode:
                            <Action
                                onClick={zipMode ? hideZip : showZip}
                                data-radleft
                                data-radright
                            >
                                {zipMode ? 'Multi (zip)' : 'Single'}
                            </Action>
                        </ActionLabel>
                        <Spacer />
                        {zipMode ? <>
                            <Input
                                type="number"
                                label="Zip Qty"
                                value={zipQuantity.toString()}
                                setValue={numericSetter(setZipQuantity, 1000)}
                                onInput={event => {
                                    numericSetter(setZipQuantity, 1000)(
                                        (event.target as HTMLInputElement).value
                                    );
                                }}
                                step={10}
                                max={1000}
                                min={0}
                            />
                            <Action
                                onClick={() => setZipType(ZipType.image)}
                                data-radleft
                            >
                                Zip {zipQuantity} Images
                            </Action>
                            <Action
                                onClick={() => setZipType(ZipType.pdf)}
                                data-radright
                            >
                                Zip {zipQuantity} PDFs
                            </Action>
                        </> : <>
                            <Action
                                onClick={onOpenPdf}
                                disabled={!pdfLoaded}
                                data-radleft
                            >Open PDF</Action>
                            <Action
                                onClick={onDownloadPdf}
                                disabled={!pdfLoaded}
                            >Download PDF</Action>
                            <Action
                                onClick={onDownloadImg}
                                disabled={!imageLoaded}
                            >Download IMG</Action>
                            <Action
                                onClick={onGenerateImage}
                                disabled={!imageLoaded}
                                data-radright
                            >New IMG</Action>
                        </>}
                    </ActionDeck>
                </MaxWidth>
                <Shadow />
            </Wrapper>
        </>
    )
}
