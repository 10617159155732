import {loadImage, loadImageDataUrl} from './load';
import {Hex6Color} from '../ui';

export enum FloodMethod {
    combine = 'combine',
    excludeTransparent = 'excludeTransparent',
}

const floodMap: Record<FloodMethod, string> = {
    combine: '<feComposite in="SourceGraphic" in2="flood" operator="arithmetic" k1="1" k2="0" k3="0" k4="0" />',

    // upon suggestion from https://stackoverflow.com/a/44013948
    excludeTransparent: `
        <feComposite in="flood" in2="SourceAlpha" operator="in" result="flood_alpha"/>
        <feBlend mode="exclusion" in="flood_alpha" in2="SourceGraphic"/>
    `,
};

export const colorFloodImage = async (
    sourceImg: HTMLImageElement,
    color: Hex6Color,
    floodMethod: FloodMethod,
): Promise<HTMLImageElement> => {
    const dataUrl = await loadImageDataUrl(sourceImg);

    // upon suggestion from https://stackoverflow.com/a/32736304
    const newImageXmlSrc = `
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width="${sourceImg.width}"
            height="${sourceImg.height}"
            style="float:left"
        >
            <defs>
                <filter id="colorMask1">
                    <feFlood flood-color="${color}" result="flood" />   
                    ${floodMap[floodMethod]}
                </filter>
            </defs>
            <image
                width="${sourceImg.width}"
                height="${sourceImg.height}"
                xlink:href="${dataUrl}"
                filter="url(#colorMask1)"
            />
        </svg>
    `;
    const imageBlob = new Blob(
        [newImageXmlSrc],
        { type: 'image/svg+xml;charset=utf-8' }
    );
    const imageUrl = window.URL.createObjectURL(imageBlob);
    const image = await loadImage(imageUrl);

    window.URL.revokeObjectURL(imageUrl);

    return image;
};
