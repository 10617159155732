import {makeNoise2D} from 'open-simplex-noise';
import {loopRectangle} from './number';

const NOISE_SCALE = 10;

export type RectangleCallback = (x: number, y: number, noise: number) => void;

export class Noise {
    private variety: boolean;
    private seed: number;
    private generator: any;
    private scale: number;
    
    constructor(scale?: number, seed?: number, variety: boolean = true) {
        this.variety = variety;
        this.seed = seed || Date.now() + Math.random()
        this.generator = makeNoise2D(this.seed);
        this.scale = scale || NOISE_SCALE;
    }

    // noise is from -1 to 1.
    // add 1 makes it always positive from 0 to 2
    // divide by 2 makes it from 0 to 1
    _singleNoiseValue = (x: number, y: number, scale?: number) => (
        this.generator(
            x / (scale || this.scale),
            y / (scale || this.scale)
        ) + 1
    ) / 2;

    at = (x: number, y: number, scale?: number) => this.variety
        ? (
            (this._singleNoiseValue(x, y, (scale || this.scale) * .5)*.1)
            + (this._singleNoiseValue(x, y, (scale || this.scale) * 4)*1)
            + (this._singleNoiseValue(x, y, (scale || this.scale) * 2)*.5)
        ) / (.1 + 1 + .5)
        : this._singleNoiseValue(x, y, scale);
    
    static rectangle(
        width: number,
        height: number,
        scale: number,
        blockSize: number = 1
    ): ((lambda: RectangleCallback) => void) {
        const maxX: number = Math.floor(width / blockSize);
        const maxY: number = Math.floor(height / blockSize);
        const noise = new Noise(scale);
    
        return (lambda: RectangleCallback): void  => {
            loopRectangle(0, 0, maxX, maxY, (x, y) => {
                lambda(x, y, noise.at(x,y));
            });
        };
    };
};
