export type ImageSize = {
    width: number;
    height: number;
};

export type CropMask = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type DataUri = string;
export type ImageUrl = string;

const promisifyImage = async (
    image: HTMLImageElement,
    imageSrc?: ImageUrl,
    size?: ImageSize
): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const cleanup = () => document.body.removeChild(image);

    image.addEventListener('load', () => {
        cleanup();
        resolve(image);
    });
    image.addEventListener('error', rejection => {
        cleanup();
        reject(rejection);
    });

    image.style.position = 'fixed';
    image.style.top = '-9999999999px';
    image.style.left = '-9999999999px';
    image.style.opacity = '0';

    if (size) {
        image.style.width = size.width.toString() + 'px';
        image.style.height = size.height.toString() + 'px;';
        image.width = size.width;
        image.height = size.height;
    }

    image.src = imageSrc ? imageSrc : image.src;
    document.body.appendChild(image);
});

export const loadImage = async (imageSrc: ImageUrl, size?: ImageSize): Promise<HTMLImageElement> => {
    try {
        return await promisifyImage(new Image(), imageSrc, size);
    } catch(error) {
        throw new Error(`Unable to load image :: ${imageSrc} :: ${error}`);
    }
};

export const loadImageDataUrl = async (
    image: HTMLImageElement,
    cropMask?: CropMask
): Promise<DataUri> => {
    if (!image.complete)
        await promisifyImage(image);

    const canvas = document.createElement('canvas');
    canvas.width = cropMask ? cropMask.width : image.width;
    canvas.height = cropMask ? cropMask.height : image.height;

    const context = canvas.getContext('2d');

    if (!context)
        throw new Error('i should really look up why this might happen');

    if (cropMask) {
        context.drawImage(image,
            cropMask.x, cropMask.y, cropMask.width, cropMask.height,
            0, 0, cropMask.width, cropMask.height
        );
    } else {
        context.drawImage(image, 0, 0, image.width, image.height);
    }

    return canvas.toDataURL('image/png');
};
