import PDFDocument from 'pdfkit-browserify';
import blobStream  from 'blob-stream';


export enum ContentType {
    text = 'contenttype/text',
    image = 'contenttype/image',
    pagebreak = 'contenttype/pagebreak',
};

export enum ResultType {
    blob = 'resulttype/blob',
    url = 'resulttype/url',
};

export enum ImageFill {
    stretch = 'imagefill/stretch',
    cover = 'imagefill/cover',
    fit = 'imagefill/fit',
};

abstract class BaseContentEntity {
    public x: number = 0;
    public y: number = 0;

    s(name: keyof this, value: any) {
        this[name] = value;
        return this;
    }
};

export class PdfContentText extends BaseContentEntity {
    public get type() { return ContentType.text };
    public size: number = 12;
    public text: string = '_TEXT_';
    public font?: string;
    public align?: string;
};

export class PdfContentImage extends BaseContentEntity {
    public get type() { return ContentType.image };
    public fill: ImageFill = ImageFill.cover;
    public data: any;
    public width?: number;
    public height?: number;
};

export class PdfContentPagebreak {
    public get type() { return ContentType.pagebreak };
};

export type PdfOptions = {
    content: Array<PdfContentImage | PdfContentText | PdfContentPagebreak>;
    resultType: ResultType;
    size: number[];
    fonts?: any;
    images?: any;
    margin?: any;
    margins?: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
};

export const generatePdf = async <T extends Blob | string>(
    {content, resultType, fonts, images, ...options}: PdfOptions
): Promise<T> => (
    new Promise((resolve, reject) => {
        const doc = new PDFDocument(options);
        const stream = doc.pipe(blobStream());

        content.forEach(item => {
            if (item.type === ContentType.pagebreak) {
                doc.addPage();
            } else if (item.type === ContentType.text) {
                const {
                    text, size, x, y, font, align
                } = item as PdfContentText;
    
                doc
                    .font(font || 'Helvetica')
                    .fontSize(size)
                    .text(text, x, y, {align});
            } else if (item.type === ContentType.image) {
                const {
                    data, x, y, width, height, fill
                } = item as PdfContentImage;
                
                const sizeOptions = fill === ImageFill.fit
                    ? {fit: [width, height], align: 'center', valign: 'center'}
                    : fill === ImageFill.cover
                        ? {cover: [width, height], align: 'center', valign: 'center'}
                        : {width, height};
    
                // clipping workaround provided here:
                // https://github.com/foliojs/pdfkit/issues/1044#issuecomment-642007002
                if (fill === ImageFill.cover) {
                    doc.save();
                    doc.rect(x, y, width || 0, height || 0).clip();
                }

                // @ts-ignore
                doc.image(data, x, y, sizeOptions);
    
                if (fill === ImageFill.cover)
                    doc.restore()
            }
        });
        doc.end();
        stream.on('finish', () => {
            resolve(
                resultType === ResultType.blob
                    ? stream.toBlob('application/pdf')
                    : stream.toBlobURL('application/pdf')
            );
        });
    })
);
