import {getDisplayDate, getDisplayTime} from '../datetime';
import {Noise} from '../noise';
import {Hex6Color} from '../ui';
import {QaImg} from './index';
import {randomIcon} from './icon';
import {colorFloodImage, FloodMethod} from './fx';


export const generateImage = async (
    width: number,
    height: number
): Promise<QaImg> => {
    const color = Hex6Color.random(4);
    const inverted = color.invert();

    const canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;
    
    const context = canvas.getContext('2d');

    if (!context)
        throw new Error('This should never happen. We dont do anything weird with getting context from this thing. but typescript demands it.');

    context.fillStyle = color.toString();
    context.fillRect(0, 0, width, height);

    const blockSize = Math.ceil(
        Math.min(width, height) / 100
    );
    const noiseScale = 8;
    Noise.rectangle(width, height, noiseScale, blockSize)(
        (x: number, y: number, noise: number) => {
            if (noise > 0.5) {
                context.fillStyle = inverted.rgba(.1);
                context.fillRect(
                    x * blockSize,
                    y * blockSize,
                    blockSize,
                    blockSize
                );
            }
        }
    );

    context.fillStyle = color.rgba(0.5);

    const textHeight = {
        bottom: height * 0.05,
        top: height * 0.25,
    };

    context.fillStyle = inverted.toString();
    context.font = `${textHeight.bottom}px 'Nanum Pen Script'`;

    context.fillText(`${getDisplayDate()} ${getDisplayTime()}`, 5, height-5);

    context.textAlign = 'center';
    context.textBaseline = 'top';
    context.font = `${textHeight.top}px 'Nanum Pen Script'`;

    const uniqueContentString = Math.random().toString(36).substr(2, 5).toUpperCase();
    context.fillText(uniqueContentString, width/2, 0);

    const availableVerticalHeight = height - textHeight.top - textHeight.bottom - (5 * 2);
    const spaceForIcon = Math.min(availableVerticalHeight, width);
    if (spaceForIcon > 10) {
        const icon = await colorFloodImage(
            await randomIcon({
                width: spaceForIcon,
                height: spaceForIcon
            }),
            inverted,
            FloodMethod.excludeTransparent
        );

        context.drawImage(
            icon,
            (width/2)-(icon.width/2),
            textHeight.top
        );
    }

    return {
        dataUri: canvas.toDataURL('image/png'),
        id: uniqueContentString,
        width,
        height,
        backgroundColor: color.toString(),
        foregroundColor: inverted.toString(),
    };
};
